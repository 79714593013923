<template>
  <router-view></router-view>
</template>

<script>
export default {
  data() {
    return {
      parentUrl: '/partenaires',
      links: [
        {
          name: 'Partenaires Promy',
          path: 'partenaires',
          componentName: 'partenaires',
          gate: [
            'consultation,partenaire',
            'edite,partenaire',
            'delete,partenaire',
          ],
        },
        {
          name: "Secteur d'activités",
          path: 'activity-sector',
          componentName: 'list-activity-sector',
          gate: [
            'consultation,partenaire',
            'edite,partenaire',
            'delete,partenaire',
          ],
        },
      ],
    }
  },
  created() {
    this.$store.commit('subSidebar/LINKS', this.links)
    this.$store.commit('subSidebar/PARENT_URL', this.parentUrl)
  },
  mounted() {
    this.$store.commit('sidebar/RESIZE_WINDOW', true)
  },
  destroyed() {
    this.$store.commit('sidebar/RESIZE_WINDOW', false)
  },
}
</script>

<style></style>
